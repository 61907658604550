<template>
  <div class="row py-5">
    <div class="logout">
      <h4>Thanks for visiting.</h4>
      <p>You have successfully signed out.</p>
    </div>
  </div>
</template>

<script>
export default { name: 'Logout' };
</script>

<style lang="stylus" scoped>

.logout
  margin 0 auto

</style>
